import {CurrentWeather} from './current-weather';
import {CurrentPollution} from './current-pollution';

export class CurrentWeatherPollution
{
  weather: CurrentWeather;
  pollution: CurrentPollution;

  constructor(weather: CurrentWeather, pollution: CurrentPollution)
  {
    this.weather = weather;
    this.pollution = pollution;
  }
}
