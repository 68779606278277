import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {UserService} from './user.service';
import {environment} from '../../environments/environment';
import {Subject} from 'rxjs';
import {Application, Customer, PollutionResponseData} from '../network/typescript-angular-client-generated';
import {Location} from './hazards/abstract-hazard';
import {WeatherPollutionServiceEx} from '../network/weather-pollution-service-ex';
import * as _ from 'lodash';
import {CurrentWeatherPollution} from './current/current-weather-pollution';
import {CurrentWeather} from './current/current-weather';
import {CurrentPollution} from './current/current-pollution';

@Injectable({providedIn: 'root'})
export class WeatherPollutionService
{
  private fetching: boolean;
  private current?: CurrentWeatherPollution;
  readonly onCurrent: Subject<CurrentWeatherPollution>;

  get weather(): CurrentWeather | null
  {
    if (_.isNull(this.current))
      return null;

    return this.current.weather;
  }

  get pollution(): CurrentPollution | null
  {
    if (_.isNull(this.current))
      return null;

    return this.current.pollution;
  }

  // readonly cacheWeather: DataCache<CurrentWeather>;
  // readonly cachePollution: DataCache<CurrentPollution>;
  // readonly onWeatherData: Subject<CurrentWeather[]>;
  // readonly onPollutionData: Subject<CurrentPollution[]>;

  constructor(private http: HttpClient, private userService: UserService, private weatherPollutionService: WeatherPollutionServiceEx)
  {
    this.weatherPollutionService.setBasePath(environment.apiUrl + '/eq-external-api/api/v1');
    this.onCurrent = new Subject<CurrentWeatherPollution>();
    this.current = null;
    this.fetching = false;
    // this.cacheWeather = new DataCache<CurrentWeather>();
    // this.cachePollution = new DataCache<CurrentPollution>();
    // this.onWeatherData = new Subject<CurrentWeather[]>();
    // this.onPollutionData = new Subject<CurrentPollution[]>();
  }

  reset()
  {
    this.current = null;
    // this.cacheWeather.reset();
    // this.cachePollution.reset();
  }

  async fetchItems(location: Location)
  {
    if (this.fetching)
      return;

    try
    {
      const response = await this.weatherPollutionService.pollution(Application._1, {latitude: location.latitude, longitude: location.longitude}, Customer._2).toPromise();

      console.info('=>' + JSON.stringify(response.data));
      const locationCurrent = new Location(response.data.city.geo[0], response.data.city.geo[1]);
      const date = new Date(response.data.time.iso);

      const weather = new CurrentWeather(date, locationCurrent, response.data.iaqi.t.v, response.data.iaqi.p.v, response.data.iaqi.h.v, response.data.iaqi.w.v);
      const pollution = new CurrentPollution('none', date, locationCurrent, response.data.iaqi.dew.v, response.data.iaqi.no2.v, response.data.iaqi.pm10.v);
      this.fetching = false;

      this.current = new CurrentWeatherPollution(weather, pollution);
      this.onCurrent.next(this.current);
    }
    catch (error)
    {
      this.fetching = false;
      this.onCurrent.error(error.error.description);
    }
  }
}
