/**
 * Alert System
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.2
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { PollutionResponseData } from './pollutionResponseData';

export interface PollutionResponse { 
    status: PollutionResponse.StatusEnum;
    data?: PollutionResponseData;
}
export namespace PollutionResponse {
    export type StatusEnum = 'ok' | 'error';
    export const StatusEnum = {
        Ok: 'ok' as StatusEnum,
        Error: 'error' as StatusEnum
    };
}