<div>
  <div class="modal-header ">
    <h4 class="modal-title" id="modal-basic-title">{{ hazards[0].place }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="onClose()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body no-padding">
    <div class="position-relative">
      <agm-map #AgmMap class="w-100" [latitude]="42.6977" [longitude]="23.3219" [fitBounds]="false" [maxZoom]="12" [usePanning]="true" [clickableIcons]="false">
        <agm-marker *ngIf="location && map != null" [latitude]="location.latitude" [longitude]="location.longitude" [iconUrl]="myLocationIcon" [zIndex]="999"
                    [agmFitBounds]="true" [animation]="'DROP'"></agm-marker>
        <agm-marker *ngFor="let item of hazards" [latitude]="item.location.latitude" [longitude]="item.location.longitude"
                    [label]="{color: 'red', fontSize: '12', fontWeight: 'bold', text: item.title}" [iconUrl]="earthquakeIcon" [agmFitBounds]="true"></agm-marker>
      </agm-map>

      <a class="position-absolute menuIcon" [routerLink]="['../list']">
        <img src="assets/list-view.svg"/>
      </a>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="onClose()">Close</button>
  </div>
<div>
