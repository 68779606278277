import {EarthquakeService} from './typescript-angular-client-generated';
import {Injectable} from '@angular/core';

@Injectable()
export class EarthquakeServiceEx extends EarthquakeService
{
  protected basePath = '';

  setBasePath(basePath: string)
  {
    this.basePath = basePath;
  }
}
