import {Component, OnInit} from '@angular/core';
import {HeaderItem} from '../header-item';
import {AppRoutingModule} from '../../../app-routing.module';
import {UserService, AuthenticationEvent} from '../../../services/user.service';
import {NavigationEnd, Router} from '@angular/router';
import * as _ from 'lodash';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit
{
  navHeight: number;
  brandAnchor: string;
  items: HeaderItem[];
  authenticated: boolean;
  isLanding: boolean;

  constructor(private routing: AppRoutingModule, private userService: UserService, private router: Router)
  {
    this.navHeight = 50;
    this.brandAnchor = '';
    this.items = routing.getHeaderItems();
    this.authenticated = !userService.getProfile().isDefault();
    this.isLanding = true;
    console.info('authenticated: ' + this.authenticated + ' landing: ' + this.isLanding + ' isDefault: ' + userService.getProfile().isDefault());
  }

  ngOnInit()
  {
    const self = this;
    this.userService.changes.subscribe({
      next(event)
      {
        switch (event)
        {
          case AuthenticationEvent.none:
            break;
          case AuthenticationEvent.didLogin:
            self.authenticated = !self.userService.getProfile().isDefault();
            console.info('authenticated: ' + self.authenticated);
            break;
          case AuthenticationEvent.didLogout:
            self.authenticated = !self.userService.getProfile().isDefault();
            console.info('authenticated: ' + self.authenticated);
            break;
        }
      }
    });

    this.router.events.subscribe((event) =>
    {
      if (event instanceof NavigationEnd)
      {
        self.isLanding = _.includes(['/hazards/map', '/hazards/list'], event.urlAfterRedirects);
      }
    });
  }

  onLogout()
  {
    this.userService.logout();
  }

  onSignup()
  {
    console.info('signup');
  }

  onSubMenu(item: HeaderItem)
  {
    console.info('subitem: onclick');
  }

  onMenu(item: HeaderItem)
  {
    console.info('item: onclick');
  }
}
