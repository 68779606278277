<div class="container">

  <div class="row justify-content-center">
    <h1 class="col-xs-12">Profile</h1>
  </div>

  <div class="row justify-content-center">

    <div class="col">

      <form [formGroup] = "formBasic">
        <input type="text" placeholder="Username" formControlName="username" class="form-control"/>
        <br/>
        <input type="text" placeholder="First Name" formControlName="firstName" class="form-control"/>
        <br/>
        <input type="text" placeholder="Last Name" formControlName="lastName" class="form-control"/>
        <br/>
        <input type="text" placeholder="Email" formControlName="email" class="form-control"/>
        <br/>
        <input type="password" placeholder="Password" formControlName="password" class="form-control"/>
        <br/>
        <input type="password" placeholder="Confirm Password" formControlName="passwordConfirm" class="form-control"/>
        <br/>
      </form>

      <button type="button" class="btn btn-primary form-control" (click)="onSubmit()" [disabled]="!formBasic.valid || !formEarthquakeSettings.valid">Update</button>

    </div>

    <div class="col">

      <form [formGroup] = "formEarthquakeSettings">
        <div><strong>Earthquake:</strong></div>
<!--        <br>-->
<!--        <div ngbDropdown>-->
<!--          <button class="btn btn-outline-primary" id="dropdownSource" ngbDropdownToggle>{{ userService.getProfile().getEarthquakeSettings().getSource().getTitle() }}</button>-->
<!--          <div ngbDropdownMenu aria-labelledby="dropdownSource">-->
<!--            <button ngbDropdownItem *ngFor="let source of userService.getProfile().getEarthquakeSettings().getSourceOptions()" class="dropdown-item" href="#" (click)="onSource(source)">{{ source.getTitle() }}</button>-->
<!--          </div>-->
<!--        </div>-->

        <br>
        <input type="number" placeholder="Magnitude" formControlName="magnitude" class="form-control"/>
        <br/>
        <input type="number" placeholder="Radius" formControlName="radius" class="form-control"/>
        <br/>
      </form>
    </div>


  </div>

</div>
