import {PollutionService} from './typescript-angular-client-generated';
import {Injectable} from '@angular/core';

@Injectable()
export class WeatherPollutionServiceEx extends PollutionService
{
  protected basePath = '';

  setBasePath(basePath: string)
  {
    this.basePath = basePath;
  }
}
