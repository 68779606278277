<div class="container">
  <div class="position-relative row">

    <div class="table-responsive">
      <table class="table table-striped">
        <thead>
        <tr>
          <th scope="col">Magnitude</th>
          <th scope="col">Severity</th>
          <th scope="col">Place</th>
          <th scope="col">News</th>
        </tr>

        </thead>
        <tbody>
        <tr *ngFor="let item of earthquakeService.cache.getItems()">
          <td>{{item.magnitude}}</td>
          <td>{{item.severity}}</td>
          <td>
            <a [routerLink]="['/', {outlets: { modal: ['map-modal', item.id] } }]">{{item.place}}</a>
          </td>
          <td>
            <button class="btn-primary" (click)="onNews(item)">News</button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>

    <a class="position-absolute menuIcon" [routerLink]="['../map']">
      <img src="assets/map-view.svg"/>
    </a>

  </div>
</div>
