import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import * as _ from 'lodash';
import {Observable, Subject} from 'rxjs';
import {EarthquakeHazard} from '../earthquake/earthquake-hazard';
import {ChatMessage} from './chat-message';
import {UserService} from '../user.service';
import {DataCache} from '../data-cache';
import {Logger} from 'angularx-logger';
import {webSocket, WebSocketSubject} from 'rxjs/webSocket';
import {delay, retryWhen, tap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ChatService
{
  private websocketSubject?: WebSocketSubject<object>;
  readonly cache: DataCache<ChatMessage>;
  readonly onMessage: Subject<ChatMessage>;

  constructor(private userService: UserService, private logger: Logger)
  {
    this.onMessage = new Subject<ChatMessage>();
    this.cache = new DataCache<ChatMessage>();
  }

  private createWebSocket(): Observable<void>
  {
    return Observable.create(observer =>
    {
      try
      {
        this.websocketSubject = webSocket(environment.chatSocketUrl);

        const subscription = this.websocketSubject.asObservable()
          .subscribe(data =>
              observer.next(data),
            error => observer.error(error),
            () => observer.complete());

        return () =>
        {
          if (!subscription.closed)
          {
            subscription.unsubscribe();
          }
        };
      } catch (error)
      {
        observer.error(error);
      }
    });
  }

  listen(listen: boolean)
  {
    if (listen)
    {
      if (!_.isNil(this.websocketSubject))
      { return; }

      this.createWebSocket().pipe(retryWhen(errors => errors.pipe(tap(err => this.logger.info(err)), delay(1000))))
        .subscribe(data =>
        {
          const message = ChatMessage.fromObject(data as unknown as {[key: string]: any});
          if (_.isNil(message))
          { return; }

          this.cache.add([message]);
          this.onMessage.next(message);
        });
    }
    else
    {
      if (_.isNil(this.websocketSubject))
      { return; }

      this.websocketSubject.unsubscribe();
      this.websocketSubject = null;
    }
  }

  send(message: string)
  {
    // if (_.isNil(this.openSocket))
    // { return; }
    //
    // const self = this;
    // const msg = new ChatMessage(this.userService.getProfile().getID(), this.userService.getProfile().getFirstName() + this.userService.getProfile().getLastName(), message);
    // this.openSocket.send(msg.asString()).then((d, socket) =>
    // {
    //   self.cache.add([msg]);
    // });
  }
}
