<div class="d-flex flex-grow-1">
  <agm-map #AgmMap class="w-100 d-flex" [latitude]="42.6977" [longitude]="23.3219" [fitBounds]="fitBounds" [maxZoom]="12"
           [usePanning]="true" [clickableIcons]="false">
    <agm-marker *ngIf="location && map != null" [latitude]="location.latitude" [longitude]="location.longitude" [iconUrl]="myLocationIcon" [zIndex]="998"
                [agmFitBounds]="true" [animation]="'DROP'"></agm-marker>
    <agm-marker *ngFor="let item of earthquakeService.cache.getItems()" [latitude]="item.location.latitude" [longitude]="item.location.longitude"
                [label]="{color: 'red', fontSize: '12', fontWeight: 'bold', text: item.title}" [iconUrl]="earthquakeIcon" [agmFitBounds]="true">
      <agm-info-window [isOpen]="item.id == activatedHazardID">
        <strong>{{item.place}}</strong>
        <div><strong>Severity: </strong>{{item.severityString}}</div>
        <div><strong>Depth: </strong>{{item.depth}}km</div>
        <div>{{item.datetime}}</div>
        <br>
        <div class="text-center"><button class="btn-primary" (click)="onNews(item)">News</button></div>
      </agm-info-window>
    </agm-marker>

    <agm-marker *ngIf="weatherPollutionService.pollution"
                [latitude]="weatherPollutionService.pollution.location.latitude"
                [longitude]="weatherPollutionService.pollution.location.longitude"
                [label]="{color: 'black', fontSize: '12', fontWeight: 'bold', text: weatherPollutionService.pollution.info.title}"
                [iconUrl]="pollutionIcon" [agmFitBounds]="true" [zIndex]="999">
      <agm-info-window>{{weatherPollutionService.pollution.info.description}}</agm-info-window>
    </agm-marker>

  </agm-map>

  <div class="card weather" *ngIf="weatherPollutionService.weather">
    <div class="card-body">
      <div><strong>Temperature: </strong>{{weatherPollutionService.weather.temperature}}℃</div>
      <div><strong>Wind Speed: </strong>{{weatherPollutionService.weather.windSpeed}} ms</div>
      <div><strong>Pressure: </strong>{{weatherPollutionService.weather.pressure}}</div>
      <div><strong>Humidity: </strong>{{weatherPollutionService.weather.humidity}}%</div>
      <div><strong>NO₂: </strong>{{weatherPollutionService.pollution.no2}}%</div>
    </div>
  </div>

  <a class="position-absolute menuIcon" [routerLink]="['../list']">
    <img src="assets/list-view.svg"/>
  </a>
</div>
