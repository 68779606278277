import {AbstractHazard, HazardSeverity, Location, HazardType} from '../hazards/abstract-hazard';
import * as _ from 'lodash';

export class CurrentWeather
{
  datetime: Date;
  location: Location;
  severity: HazardSeverity;
  type: HazardType;

  temperature: number;
  pressure: number;
  humidity: number;
  windSpeed: number;

  constructor(datetime: Date, location: Location, temperature: number, pressure: number, humidity: number, windSpeed: number)
  {
    this.datetime = datetime;
    this.location = location;
    this.severity = HazardSeverity.medium;
    this.type = HazardType.weather;

    this.temperature = temperature;
    this.pressure = pressure;
    this.humidity = humidity;
    this.windSpeed = windSpeed;
  }

  // getWindDirection(): string
  // {
  //   const cardinals = ['North', 'North East', 'East', 'South East', 'South', 'South West', 'West', 'North West', 'North'];
  //   return cardinals[_.round((this.wd % 360) / 45)];
  // }
}
