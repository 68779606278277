import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {NgbActiveModal, NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {MapModalContentComponent} from './map-modal-content.component';
import {NewsModalContentComponent} from '../news-modal/news-modal-content.component';
import {interval, pipe, Subject, Subscription} from 'rxjs';
import {timeout} from 'rxjs/operators';

@Component({
  selector: 'map-modal-component',
  template: ' '
})
export class MapModalComponent implements OnInit
{
  private hazardIDSubject: Subscription;
  private modalRef?: NgbModalRef;

  constructor(private modalService: NgbModal, private route: ActivatedRoute, private location: Location)
  {}

  ngOnInit(): void
  {
    const self = this;
    this.hazardIDSubject = this.route.params.subscribe(params =>
    {
      const hazardID = params['hazard-id'];

      setTimeout(() =>
      {
        const modalRef = this.modalService.open(MapModalContentComponent);
        (modalRef.componentInstance as MapModalContentComponent).hazardID = hazardID;

        modalRef.result.then((result) =>
        {
          this.location.back();
        }, (reason) =>
        {
          this.location.back();
        });

        console.info('src: ' + hazardID);
      }, 100);
    });
  }
}
