import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {Location} from '../../../services/hazards/abstract-hazard';
import {EarthquakeHazard} from '../../../services/earthquake/earthquake-hazard';
import {GeoLocationService} from '../../../services/geo-location.service';
import {EarthquakeService} from '../../../services/earthquake/earthquake.service';
import {GoogleMap} from '@agm/core/services/google-maps-types';
import {AgmMap} from '@agm/core';
import * as _ from 'lodash';
import {WeatherPollutionService} from '../../../services/weather-pollution.service';
import {Logger} from 'angularx-logger';
import {ChatService} from '../../../services/chat/chat.service';
import {PollutionInfo} from '../../../services/current/current-pollution';
import {ActivatedRoute, Route} from '@angular/router';

@Component({
  selector: 'app-hazards-map',
  templateUrl: './hazards-map.component.html',
  styleUrls: ['./hazards-map.component.scss']
})
export class HazardsMapComponent implements OnInit, AfterViewInit
{
  location?: Location;
  map?: GoogleMap;
  fitBounds = true;
  myLocationIcon = {url: 'assets/my_location.svg', scaledSize: { width: 50,  height: 50}};
  earthquakeIcon = {url: 'assets/earthquake.gif', scaledSize: { width: 35,  height: 35}};
  pollutionIcon = {};
  activatedHazardID?: string;

  @ViewChild('AgmMap') agmMap: AgmMap;

  constructor(private geoLocationService: GeoLocationService,
              public earthquakeService: EarthquakeService,
              public weatherPollutionService: WeatherPollutionService,
              public chatService: ChatService,
              private logger: Logger,
              private route: ActivatedRoute)
  {}

  ngOnInit()
  {}

  ngAfterViewInit()
  {
    const self = this;
    this.route.queryParams.subscribe(value =>
    {
      self.activatedHazardID = _.get(value, 'id');
      this.logger.info('activatedHazardID: ' + self.activatedHazardID);
    });
    // ----------------------------------------------------------------------------------------------------
    if (this.geoLocationService.location)
      this.location = this.geoLocationService.location;
    // ----------------------------------------------------------------------------------------------------
    this.agmMap.mapReady.subscribe(map =>
    {
      self.map = map;
      self.fitToBounds();
    });

    this.geoLocationService.subject.subscribe(async location  =>
    {
      self.logger.info('[map] ✅ location received');
      self.location = location;
      self.fitBounds = false;
      self.earthquakeService.reset();
      self.weatherPollutionService.reset();
      self.earthquakeService.fetchItems();
      await self.weatherPollutionService.fetchItems(location);
    });
    // ----------------------------------------------------------------------------------------------------
    this.earthquakeService.onData.subscribe(items =>
    {
      self.logger.info('[map] ✅ earthquake received');
      self.fitToBounds();
    });
    this.fitToBounds();

    this.weatherPollutionService.onCurrent.subscribe(current =>
    {
      self.logger.info('[map] ✅ weather pollution received');
      self.makePollutionIcon(current.pollution.info);
    });

    this.earthquakeService.listen(true);
    this.chatService.listen(true);

    const currentPollution = this.weatherPollutionService.pollution;
    if (currentPollution)
    {
      this.makePollutionIcon(currentPollution.info);
    }
  }

  fitToBounds()
  {
    if (_.isNil(this.map) || _.isNil(this.location))
      return;

    const bounds = new window['google'].maps.LatLngBounds();
    bounds.extend(new window['google'].maps.LatLng(this.location.latitude, this.location.longitude));

    this.earthquakeService.cache.getItems().forEach(hazard =>
    {
      bounds.extend(new window['google'].maps.LatLng(hazard.location.latitude, hazard.location.longitude));
    });
    this.map.fitBounds(bounds);
  }

  makePollutionIcon(info: PollutionInfo)
  {
    this.pollutionIcon =
      {
        url: 'assets/pollution_' + info.level.toString() + '.svg',
        labelOrigin: new window['google'].maps.Point(20, 50),
        scaledSize: { width: 40,  height: 40}
      };
  }

  onNews(item: EarthquakeHazard)
  {
    window.open(this.earthquakeService.makeNewsURL(item), 'popup', 'width=1000,height=600');
  }
}
