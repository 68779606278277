import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {EarthquakeServiceEx} from '../../network/earthquake-service-ex';
import {Observable, Subject} from 'rxjs';
import {Application, Customer, EarthquakeSource} from '../../network/typescript-angular-client-generated';
import {UserService} from '../user.service';
import {EarthquakeHazard, Source} from './earthquake-hazard';
import * as _ from 'lodash';
import {Location} from '../hazards/abstract-hazard';
import {DataCache} from '../data-cache';
import {environment} from '../../../environments/environment';
import {Logger} from 'angularx-logger';
import {delay, retryWhen, tap} from 'rxjs/operators';
import {webSocket, WebSocketSubject} from 'rxjs/webSocket';

@Injectable({
  providedIn: 'root'
})
export class EarthquakeService
{
  private websocketSubject?: WebSocketSubject<object>;
  readonly cache: DataCache<EarthquakeHazard>;
  private fetching: false;
  readonly onData: Subject<EarthquakeHazard[]>;

  constructor(private http: HttpClient, private userService: UserService, private earthquakeService: EarthquakeServiceEx,
              private logger: Logger)
  {
    this.earthquakeService.setBasePath(environment.apiUrl + '/eq-admin-api/api/v1');
    this.cache = new DataCache<EarthquakeHazard>();
    this.onData = new Subject<EarthquakeHazard[]>();
  }

  private createWebSocket(): Observable<void>
  {
    return Observable.create(observer =>
    {
      try
      {
        this.websocketSubject = webSocket(environment.earthquakeSocketUrl);

        const subscription = this.websocketSubject.asObservable()
          .subscribe(data =>
          {
            this.logger.info('SEISC data: ' + JSON.stringify(data));
            observer.next(data);
          },
            error =>
            {
              this.logger.info('SEISC error: ' + JSON.stringify(error));
              observer.error(error);
            },
            () =>
            {
              this.logger.info('SEISC COMPLETE');
              observer.complete();
            });

        return () =>
        {
          if (!subscription.closed)
          {
            subscription.unsubscribe();
          }
        };
      } catch (error)
      {
        observer.error(error);
      }
    });
  }

  listen(listen: boolean)
  {
    if (listen)
    {
      if (!_.isNil(this.websocketSubject))
      { return; }

      this.createWebSocket().pipe(retryWhen(errors => errors.pipe(tap(err => this.logger.info(err)), delay(1000))))
        .subscribe(data =>
        {
          const hazard = EarthquakeHazard.fromObject(data as unknown as {[key: string]: object});
          if (_.isNil(hazard))
          { return; }

          this.cache.add([hazard]);
          this.onData.next(this.cache.getItems());
        });
    }
    else
    {
      if (_.isNil(this.websocketSubject))
      { return; }

      this.websocketSubject.unsubscribe();
      this.websocketSubject = null;
    }
  }

  reset()
  {
    this.cache.reset();
  }

  fetchItems()
  {
    if (this.fetching)
      return;

    const self = this;
    const pageToFetch = this.cache.getPage();
    if (_.isNull(pageToFetch))
    {
      this.fetching = false;
      this.onData.next([]);
    }
    else
    {
      this.logger.info('==>️ earthquake');
      const sourceNetType: EarthquakeSource = EarthquakeSource.Us;
      // switch (this.userService.getProfile().getEarthquakeSettings().getSource().getType())
      // {
      //   case EarthquakeSourceType.us:
      //     sourceNetType = EarthquakeSource.Us;
      //     break;
      //   case EarthquakeSourceType.eu:
      //     sourceNetType = EarthquakeSource.Eu;
      //     break;
      // }

      try
      {
        this.earthquakeService.earthquake(Application._1, sourceNetType, Customer._2, pageToFetch).subscribe(response =>
          {
            const hazards = _.map(response.list, value => {

              let source: Source | undefined;
              switch (sourceNetType)
              {
                case EarthquakeSource.Eu:
                  source = Source.eu;
                  break;
                case EarthquakeSource.Us:
                  source = Source.us;
                  break;
              }

              const location = new Location(value.coordinates.latitute, value.coordinates.longitude);
              return new EarthquakeHazard(value.id,  source, new Date(value.timestamp), location, value.location, value.coordinates.depth,
                value.magnitude.toString(), value.magnitude, value.tsunami);
            });
            self.cache.add(hazards);
            console.info('!!!! cache: ' + _.map(self.cache.getItems(), value => value.id));

            if (!response.total)// mark end
              self.cache.add([]);

            this.fetching = false;
            self.onData.next(hazards);
          },
          error =>
          {
            self.fetching = false;
            self.onData.error(error.error.description);
          });
      }
      catch (e)
      {
        self.logger.error(e.toString());
      }
    }
  }

  makeNewsURL(item: EarthquakeHazard): string
  {
    const baseURL = 'https://www.google.com/search?q=';
    const date = new Date(item.datetime);
    const correctedDayNo = (date.getDay() === 0) ? 7 : date.getDay();
    const dateString = _.join([correctedDayNo, date.getMonth(), date.getFullYear()], '/');
    const terms = [dateString, 'earthquake', item.place, item.location.latitude + ', ' + item.location.longitude, 'hazard'];
    const searchTerm = _.join(terms, '+');
    return encodeURI(baseURL + searchTerm);
  }
}
