import { Component } from '@angular/core';
import {environment} from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent
{
  title = 'Geo Alert';
  version: string;

  constructor()
  {
    this.version = `Version: ${environment.version}`;
  }

}
