import {AbstractControl, FormControl, ValidatorFn} from '@angular/forms';
import * as _ from 'lodash';

export class ValidatorUtils
{
  static dependant(dependant: AbstractControl): ValidatorFn
  {
    return (control: AbstractControl): { [key: string]: boolean } | null =>
    {
      if (control.value === dependant.value)
      {
        if (_.has(dependant.errors, 'dependant'))
        {
          control.setErrors(null);
          dependant.updateValueAndValidity();
        }
        console.info('valid');
        return null;
      }
      else
      {
        if (!_.has(dependant.errors, 'dependant'))
        {
          control.setErrors({dependant: true});
          dependant.updateValueAndValidity();
        }
        console.info('invalid');
        return {dependant: true};
      }
    };
  }

  static alphaNumeric(): ValidatorFn
  {
    return (control: FormControl): { [key: string]: boolean } | null =>
    {
      if (control.value)
      {
        const regEx = new RegExp(/^[a-zA-Z0-9]*$/);
        if (!regEx.test(control.value))
        {
          return { alphanumeric: true };
        }
      }
      return null;
    };
  }
}
