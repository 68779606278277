import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {UserService} from '../../../services/user.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  form: FormGroup;

  constructor(fb: FormBuilder, private authenticateService: UserService, private router: Router)
  {
    this.form = fb.group({
      username: new FormControl(this.authenticateService.getProfile().getEmail(),  Validators.compose([Validators.required]))
    });
  }

  ngOnInit()
  {
  }

  onSubmit()
  {
    console.info('on submit');
    this.router.navigate(['..']);
  }
}
