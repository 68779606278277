<!--<nav class="navbar bg-dark navbar-dark p-3">-->
<nav class="navbar navbar-expand-sm bg-dark navbar-dark p-2">
  <a class="navbar-brand" href="#" routerLink="..">
        <img alt="Brand" src="../../../../assets/logo.png"/>
        <span class="d-none">Geo Alert</span>
  </a>

  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarText">
<!-- -->
    <ul class="navbar-nav">
      <div *ngFor="let item of items">
        <ng-template  *ngIf="item.subItems.length > 0; then subItemsBlock; else noSubitemsBlock;"></ng-template>

        <ng-template #noSubitemsBlock>
          <li class="nav-item" (click)="onMenu(item)">
            <a *ngIf="!item.subItems.length" class="nav-link" [href]="item.path">{{ item.title }}</a>
          </li>
        </ng-template>

        <ng-template #subItemsBlock>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="navbardrop" data-toggle="dropdown">{{item.title}}</a>
            <div class="dropdown-menu">
              <a class="dropdown-item" *ngFor="let subItem of item.subItems" [href]="subItem.path">{{subItem.title}}</a>
            </div>
          </li>
        </ng-template>

      </div>
    </ul>

    <ng-container *ngIf="authenticated; then loggedIn; else anonymous;"></ng-container>
<!-- -->
  </div>

    <ng-template #anonymous>
      <ul class="nav navbar-nav ml-auto" *ngIf="isLanding">
        <li class="nav-item">
          <a routerLink="/login" href="#" class="nav-link">Login</a>
        </li>
        <li class="nav-item">
          <a routerLink="/registration" href="#" class="nav-link">Sign Up</a>
        </li>
      </ul>
    </ng-template>

    <ng-template #loggedIn>

      <ul class="nav navbar-nav ml-auto d-none d-sm-block"><!-- show on NON xs only -->
        <div class="d-inline-flex">
          <li class="nav-item">
            <a routerLink="/profile" href="#" class="nav-link">
              <img alt="profile" class="rounded-circle profile" src="assets/profile.svg"/>
            </a>
          </li>
          <li class="nav-item" style="margin: auto;">
            <a (click)="onLogout()" href="#" class="nav-link">Logout</a>
          </li>
        </div>
      </ul>

      <ul class="nav navbar-nav ml-auto d-block d-sm-none p-2"><!-- show on xs only -->
        <li class="nav-item">
          <div class="d-flex flex-row mr-auto align-items-center">
            <a routerLink="/profile" href="#" class="nav-link" style="padding-right: 15px;">
              <img alt="profile" class="rounded-circle profile" src="assets/profile.svg"/>
            </a>
            <a (click)="onLogout()" href="#" class="nav-link mr-auto">Logout</a>
          </div>
        </li>
      </ul>

    </ng-template>

</nav>
