/**
 * Alert System
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.2
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type Application = '1' | '2' | '3';

export const Application = {
    _1: '1' as Application,
    _2: '2' as Application,
    _3: '3' as Application
};