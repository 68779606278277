import { Injectable } from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse} from '@angular/common/http';
import {Observable, BehaviorSubject, throwError} from 'rxjs';
import {catchError, switchMap} from 'rxjs/operators';
import {UserService} from './user.service';
import * as _ from 'lodash';
import {error} from '@angular/compiler/src/util';

@Injectable()
export class AuthHttpInterceptor implements HttpInterceptor
{
  private excludeCalls: {[key: string]: string} = {'/tokens': 'GET', '/users': 'POST'};
  private isRefreshingToken = false;
  private tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor(public authService: UserService)
  {
    console.info('');
  }

  private addToken(request: HttpRequest<any>, token: string): HttpRequest<any>
  {
    return request.clone({headers: request.headers.append('Authorization', 'Bearer ' + token)});
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any> | any>
  {
    if (this.shouldExclude(request))
    {
      return next.handle(request);
    }

    const newRequest = this.addToken(request, this.authService.getProfile().getToken());
    return next.handle(newRequest).pipe(
        catchError(err =>
        {
          if (err instanceof HttpErrorResponse)
          {
            switch ((err as HttpErrorResponse).status)
            {
              case 401:
                return this.handle401(request, next);
              case 400:
                return this.authService.logout() as any;
            }
          }
          else
          {
            return throwError(err);
          }
        }));
  }

  private handle401(request: HttpRequest<any>, next: HttpHandler)
  {
    return this.authService.refreshToken().pipe(switchMap((token, index) =>
    {
      const newRequest = this.addToken(request, token);

      const keys = newRequest.headers.keys();
      let values = '';
      keys.forEach(key =>
      {
        values += key + ' - ' + newRequest.headers.get(key) + '\n';
      });

      console.info('refreshed token: ' + token + ' headers: ' + values);
      return next.handle(newRequest);
    }));
  }

  private shouldExclude(request: HttpRequest<any>)
  {
    return _.find(this.excludeCalls, (value, key) =>
    {
      return request.url.endsWith(key) && request.method === value;
    });
  }
}
