<!--<div class="container-fluid no-gutters">-->
<ngx-loading-bar [includeSpinner]="false"></ngx-loading-bar>
<div class="d-flex flex-column vh-100">
  <div style="z-index: 999">
    <app-header></app-header>
  </div>
  <div class="d-flex flex-grow-1" style="overflow: scroll;">
    <router-outlet></router-outlet>
  </div>
  <router-outlet name="modal"></router-outlet>
  <div class="d-flex">
    <app-footer></app-footer>
  </div>
</div>
<!--</div>-->
<span style="position: fixed; bottom: 10px; right: 10px; color: rgba(255, 255, 255, 0.5); font-size: 15px; font-family: Lato, sans-serif;"><strong>{{version}}</strong></span>
