<br>
<br>
<br>
<br>
<div class="container">

  <div class="row justify-content-center">
    <h1 class="col-xs-12">Reset Password</h1>
  </div>

  <div class="row justify-content-center">
    <br/>
    <div class="col-lg-4 col-lg-offset-4 col-sm-6 col-sm-offset-3 col-xs-10 col-xs-offset-1">

      <form [formGroup] = "form">
        <input type="text" placeholder="Email..." formControlName="username" class="form-control"/>
        <br/>
      </form>

      <button type="button" class="btn btn-primary form-control" (click)="onSubmit()" [disabled]="!form.valid">Submit</button>
    </div>
  </div>

</div>
