import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, FormControl, Validators} from '@angular/forms';
import {UserService} from '../../../services/user.service';
import {Router} from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit
{
  form: FormGroup;

  constructor(fb: FormBuilder, private authenticateService: UserService, private router: Router)
  {
    this.form = fb.group({
      username: new FormControl(this.authenticateService.getProfile().getUsername(),  Validators.compose([Validators.required])),
      password: new FormControl(this.authenticateService.getProfile().getPassword(), Validators.compose([Validators.required]))
    });
  }

  ngOnInit()
  {
    console.info('login');
  }

  onLogin()
  {
    console.info('on login');
    const self = this;
    const subscription = this.authenticateService.login(this.form.get('username').value, this.form.get('password').value, false).subscribe({
      error(msg)
      {
        subscription.unsubscribe();
        Swal.fire({text: msg, icon: 'error'});
      },
      complete()
      {
        subscription.unsubscribe();
        self.router.navigate(['']);
      }
    });
  }
}
