import {Component, OnInit} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Location} from '@angular/common';
import {NewsModalContentComponent} from './news-modal-content.component';
import {ActivatedRoute} from '@angular/router';
import {Subscription} from 'rxjs';

@Component({
  selector: 'news-modal-component',
  template: ' '
})
export class NewsModalComponent implements OnInit
{
  private hazardIDSubject: Subscription;

  constructor(private modalService: NgbModal, private route: ActivatedRoute, private location: Location) {}

  ngOnInit(): void
  {
    const self = this;
    this.hazardIDSubject = this.route.params.subscribe(params =>
    {
      const hazardID = params['hazard-id'];
      const modalRef = this.modalService.open(NewsModalContentComponent);
      (modalRef.componentInstance as NewsModalContentComponent).hazardID = hazardID;

      modalRef.result.then((result) =>
      {
        this.location.back();
      }, (reason) =>
      {
        this.location.back();
      });

      console.info('src: ' + hazardID);
    });
  }
}

