import { Component, OnInit } from '@angular/core';
import {AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators} from '@angular/forms';
import {UserService} from '../../services/user.service';
import * as _ from 'lodash';
import {ValidatorUtils} from '../../utils/validator-utils';
import swal, {SweetAlertOptions} from 'sweetalert2';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {EarthquakeSource} from '../../services/profile';
import {ActivatedRoute} from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  formBasic: FormGroup;
  formEarthquakeSettings: FormGroup;

  constructor(private formBuilder: FormBuilder, private activatedRoute: ActivatedRoute, public userService: UserService)
  {
    const user = this.activatedRoute.data['value']['data'];

    const noPassword = null;
    const passwordControl = new FormControl(noPassword, Validators.compose([]));
    const passwordControlConfirm = new FormControl(noPassword, Validators.compose([]));
    const passwordControlValidators = Validators.compose([ValidatorUtils.dependant(passwordControlConfirm), Validators.min(6)]);
    const passwordControlConfirmValidators = Validators.compose([ValidatorUtils.dependant(passwordControl), Validators.min(6)]);
    passwordControl.setValidators(passwordControlValidators);
    passwordControlConfirm.setValidators(passwordControlConfirmValidators);

    this.formBasic = this.formBuilder.group({
      username: new FormControl(this.userService.getProfile().getUsername(),  Validators.compose([Validators.required, ValidatorUtils.alphaNumeric()])),
      password: passwordControl,
      passwordConfirm: passwordControlConfirm,
      firstName: new FormControl(this.userService.getProfile().getFirstName(),  Validators.compose([Validators.min(3)])),
      lastName: new FormControl(this.userService.getProfile().getLastName(),  Validators.compose([Validators.min(3)])),
      email: new FormControl(this.userService.getProfile().getEmail(),  Validators.compose([Validators.email])),
    });

    this.formEarthquakeSettings = this.formBuilder.group({
      magnitude: new FormControl(this.userService.getProfile().getEarthquakeSettings().getMaxMagnitude(),  Validators.compose([])),
      radius: new FormControl(this.userService.getProfile().getEarthquakeSettings().getRadius(),  Validators.compose([]))
    });
  }

  ngOnInit()
  {
    const self = this;
    this.userService.fetchProfile().subscribe(() =>
    {
      self.formBasic.setValue({username: self.userService.getProfile().getUsername()});
      self.formBasic.setValue({firstName: self.userService.getProfile().getFirstName()});
      self.formBasic.setValue({lastName: self.userService.getProfile().getLastName()});
      self.formBasic.setValue({email: self.userService.getProfile().getEmail()});
    });
  }

  getControlValue(form: FormGroup, fieldName: string)
  {
    return form.get(fieldName).pristine ? null : form.get(fieldName).value;
  }

  onSubmit()
  {
    console.info('on update');
    const subscription = this.userService.update(
      this.getControlValue(this.formBasic, 'username'),
      this.getControlValue(this.formBasic, 'password'),
      this.getControlValue(this.formBasic, 'firstName'),
      this.getControlValue(this.formBasic, 'lastName'),
      this.getControlValue(this.formBasic, 'email'),
      this.getControlValue(this.formEarthquakeSettings, 'magnitude'),
      this.getControlValue(this.formEarthquakeSettings, 'radius')).subscribe({
      error(msg)
      {
        subscription.unsubscribe();
        Swal.fire({text: msg, icon: 'error'});
      },
      complete()
      {
        subscription.unsubscribe();
        Swal.fire({icon: 'success', timer: 3000, text: 'Saved'});
      }
    });
  }

  onSource(source: EarthquakeSource)
  {
    this.userService.getProfile().getEarthquakeSettings().setSource(source);
  }

}
