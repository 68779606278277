import {AbstractHazard} from '../services/hazards/abstract-hazard';
import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {EarthquakeService} from '../services/earthquake/earthquake.service';

@Injectable()
export class HazardsResolver implements Resolve<[AbstractHazard]>
{
  constructor(private earthquakeService: EarthquakeService)
  {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any>|Promise<any>|any
  {
    return this.earthquakeService.fetchItems();
  }
}
