import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {AbstractHazard} from '../../services/hazards/abstract-hazard';
import {EarthquakeService} from '../../services/earthquake/earthquake.service';
import {Observable} from 'rxjs';
import {UserService} from '../../services/user.service';

@Injectable()
export class ProfileResolver implements Resolve<[AbstractHazard]>
{
  constructor(private userService: UserService)
  {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any>|Promise<any>|any
  {
    return this.userService.fetchProfile();
  }
}
