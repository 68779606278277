import {Component, OnInit} from '@angular/core';
import {UserService} from '../../services/user.service';
import {GeoLocationService} from '../../services/geo-location.service';

@Component({
  selector: 'app-hazards',
  templateUrl: './hazards.component.html',
  styleUrls: ['./hazards.component.scss']
})
export class HazardsComponent implements OnInit
{
  constructor(private geoLocationService: GeoLocationService)
  {}

  ngOnInit()
  {
    console.info('hazards');
  }
}
