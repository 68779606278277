import {Component, OnInit, ViewChild} from '@angular/core';
import {AbstractHazard, Location} from '../../../services/hazards/abstract-hazard';
import {EarthquakeService} from '../../../services/earthquake/earthquake.service';
import {EarthquakeHazard, Source} from '../../../services/earthquake/earthquake-hazard';
import * as _ from 'lodash';
import {WeatherPollutionService} from '../../../services/weather-pollution.service';
import {GeoLocationService} from '../../../services/geo-location.service';
import {Logger} from 'angularx-logger';
import {ChatService} from '../../../services/chat/chat.service';

@Component({
  selector: 'app-hazards-list',
  templateUrl: './hazards-list.component.html',
  styleUrls: ['./hazards-list.component.scss']
})
export class HazardsListComponent implements OnInit
{
  constructor(private geoLocationService: GeoLocationService,
              public earthquakeService: EarthquakeService,
              private weatherPollutionService: WeatherPollutionService,
              private chatService: ChatService,
              private logger: Logger)
  {}

  private static makeURL(item: EarthquakeHazard)
  {
    const baseURL = 'https://www.google.com/search?q=';
    const date = new Date(item.datetime);
    const month = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December' ][date.getMonth()];
    const dateString = _.join([date.getFullYear(), date.getMonth(), date.getDay()], '/');
    const terms = [dateString, item.location.latitude + ', ' + item.location.longitude, item.magnitude, 'earthquake', 'hazard'];
    const searchTerm = _.join(terms, '+');
    return encodeURI(baseURL + searchTerm);
  }

  ngOnInit()
  {
    const self = this;
    this.geoLocationService.subject.subscribe(async location  =>
    {
      self.logger.info('[list] ✅ location received');
      self.earthquakeService.fetchItems();
      await self.weatherPollutionService.fetchItems(location);
    });

    this.earthquakeService.onData.subscribe(items =>
    {
      self.logger.info('[list] ✅ earthquake received');
    });

    this.weatherPollutionService.onCurrent.subscribe(current =>
    {
      self.logger.info('[list] ✅ weather pollution received');
    });

    this.earthquakeService.listen(true);
    this.chatService.listen(true);
  }

  onNews(item)
  {
    window.open(HazardsListComponent.makeURL(item), 'popup', 'width=1000,height=600');
  }
}
