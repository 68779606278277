import {Injectable, OnInit} from '@angular/core';
import {observable, Observable, Subject, Subscription} from 'rxjs';
import {Location} from './hazards/abstract-hazard';
import {AuthenticationEvent, UserService} from './user.service';

@Injectable({
  providedIn: 'root'
})
export class GeoLocationService
{
  subject: Subject<Location> = new Subject<Location>();
  location?: Location;

  private watchID?: number;

  constructor(private userService: UserService)
  {
    this.start();
  }

  private static parseError(error): string
  {
    const messages = ['User denied the request for Geolocation', 'Location information is unavailable', 'The request to get user location timed out', 'An unknown error occurred'];
    return messages[error.code + 1];
  }

  private start()
  {
    const self = this;

    if (navigator.geolocation)
    {
      console.log('GPS requested...');
      this.watchID = navigator.geolocation.watchPosition(position =>
      {
        console.log('GPS received');
        self.location = new Location(position.coords.latitude, position.coords.longitude);
        self.subject.next(self.location);
        self.userService.updateLocation(self.location);

      }, error =>
      {
        const message = GeoLocationService.parseError(error);
        console.info(message);
        self.subject.error(message);
      });
    }
    else
    {
      console.log('GPS no objejct');
      const message = GeoLocationService.parseError({code: -1});
      self.subject.error(message);
    }
  }

  private stop()
  {
    navigator.geolocation.clearWatch(this.watchID);
  }

  // update(): Observable<Location>
  // {
  //   const self = this;
  //   return new Observable((observer) => {
  //
  //     if (navigator.geolocation)
  //     {
  //       console.log('GPS requested...');
  //       navigator.geolocation.getCurrentPosition(position =>
  //       {
  //         console.log('GPS received');
  //         self.location = new Location(position.coords.latitude, position.coords.longitude);
  //         observer.next(self.location);
  //       }, error => {
  //
  //         const message = GeoLocationService.parseError(error);
  //         console.info(message);
  //         observer.error(message);
  //       });
  //     }
  //     else
  //     {
  //       const message = GeoLocationService.parseError({code: -1});
  //       observer.error(message);
  //     }
  //   });
  // }
}
