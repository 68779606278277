import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule, HttpInterceptor} from '@angular/common/http';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { AgmCoreModule } from '@agm/core';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ProfileComponent } from './components/profile/profile.component';
import { LoginComponent } from './components/onboarding/login/login.component';
import { ResetPasswordComponent } from './components/onboarding/reset-password/reset-password.component';
import { HeaderComponent } from './components/shared/header/header.component';
import { FooterComponent } from './components/shared/footer/footer.component';
import { RegistrationComponent } from './components/onboarding/registration/registration.component';
import {UserServiceEx} from './network/user-service-ex';
import { HazardsComponent } from './components/hazards/hazards.component';
import {EarthquakeServiceEx} from './network/earthquake-service-ex';
import {WeatherPollutionServiceEx} from './network/weather-pollution-service-ex';
import { HazardsMapComponent } from './components/hazards/hazards-map/hazards-map.component';
import { HazardsListComponent } from './components/hazards/hazards-list/hazards-list.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import {AuthHttpInterceptor} from './services/auth-http-interceptor';
import { NewsModalComponent } from './components/modals/news-modal/news-modal.component';
import {MapModalContentComponent} from './components/modals/map-modal/map-modal-content.component';
import {MapModalComponent} from './components/modals/map-modal/map-modal.component';
import {NewsModalContentComponent} from './components/modals/news-modal/news-modal-content.component';
import {UserService} from './services/user.service';
import {environment} from '../environments/environment';
import {AngularXLoggerModule} from 'angularx-logger';
import {SweetAlert2Module} from '@sweetalert2/ngx-sweetalert2';

export function init_app(userService: UserService)
{
  console.info('init_app');
  return () => userService.setup();
}

@NgModule({
  declarations: [
    AppComponent,
    ProfileComponent,
    LoginComponent,
    ResetPasswordComponent,
    HeaderComponent,
    FooterComponent,
    RegistrationComponent,
    HazardsComponent,
    HazardsMapComponent,
    HazardsListComponent,
    MapModalContentComponent,
    MapModalComponent,
    NewsModalContentComponent,
    NewsModalComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    LoadingBarHttpClientModule,
    NgbModule,
    AngularXLoggerModule.forRoot({enable: true, globalPrefix: ''}),
    SweetAlert2Module.forRoot({
      dismissOnDestroy: true
    }),
    AgmCoreModule.forRoot({
      apiKey: environment.googleMapsApiKey
    })
  ],
  providers: [
    UserServiceEx,
    EarthquakeServiceEx,
    WeatherPollutionServiceEx,
    { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true},
    { provide: APP_INITIALIZER, useFactory: init_app, deps: [UserService], multi: true }
  ],
  entryComponents: [MapModalContentComponent, NewsModalContentComponent],
  bootstrap: [AppComponent]
})
export class AppModule { }
