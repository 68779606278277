import {Location, HazardType} from '../hazards/abstract-hazard';

export enum PollutionLevel
{
  good = 0,
  moderate,
  unhealthyForSensitiveGroups,
  unhealthy,
  veryUnhealthy,
  hazardous
}

export class PollutionInfo
{
  color: string;
  level: PollutionLevel;
  title: string;
  description: string;

  private detectLevel(p10: number): PollutionLevel
  {
    // p10 -= 15; // 15 is a minimal value
    // const step = 78 / (PollutionLevel.hazardous - PollutionLevel.good + 1);
    const step = 50 / (PollutionLevel.hazardous - PollutionLevel.good + 1);

    console.info(`step: ${step}. param: ${p10}`);

    for (let level = PollutionLevel.good; level <= PollutionLevel.hazardous; ++level)
    {
      if (p10 / (step * (level + 1)) < 1)
      {
        return level;
      }
    }
    return PollutionLevel.hazardous;
  }

  constructor(aqius: number)
  {
    this.level = this.detectLevel(aqius);
    this.title = ['Good', 'Moderate', 'Unhealthy for Sensitive Groups', 'Unhealthy', 'Very Unhealthy', 'Hazardous'][this.level];
    this.color = ['#00e400', '#ffff00', '#ff7e00', '#ff0000', '#8f3f97', '#7e0023'][this.level];

    this.description =
    ['Air quality is considered satisfactory, and air pollution poses little or no risk',
    'Air quality is acceptable; however, for some pollutants there may be a moderate health concern for a very small number of people who are unusually sensitive to air pollution.',
    'Members of sensitive groups may experience health effects. The general public is not likely to be affected.',
    'Everyone may begin to experience health effects; members of sensitive groups may experience more serious health effects.',
    'Health alert: everyone may experience more serious health effects.',
    'Health warnings of emergency conditions. The entire population is more likely to be affected.'][this.level];
  }
}

export class CurrentPollution
{
  datetime: Date;
  location: Location;
  type: HazardType;

  readonly dew: number;
  readonly no2: number;
  private pm10: number;

  readonly info: PollutionInfo;

  constructor(id: string, datetime: Date, location: Location, dew: number, no2: number, pm10: number)
  {
    this.datetime = datetime;
    this.location = location;
    this.type = HazardType.pollution;

    this.datetime = datetime;
    this.dew = dew;
    this.no2 = no2;
    this.pm10 = pm10;

    this.info = new PollutionInfo(this.pm10);
  }
}
