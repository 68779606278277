import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {UserService} from '../../../services/user.service';
import {UserServiceEx} from '../../../network/user-service-ex';
import {ValidatorUtils} from '../../../utils/validator-utils';
import {Router} from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit {

  form: FormGroup;

  constructor(fb: FormBuilder, private authenticateService: UserService, private userServiceEx: UserServiceEx, private router: Router)
  {
    const passwordControl = new FormControl('', Validators.compose([]));
    const passwordControlConfirm = new FormControl('', Validators.compose([]));
    const passwordControlValidators = Validators.compose([ValidatorUtils.dependant(passwordControlConfirm), Validators.min(6), Validators.required]);
    const passwordControlConfirmValidators = Validators.compose([ValidatorUtils.dependant(passwordControl), Validators.min(6), Validators.required]);
    passwordControl.setValidators(passwordControlValidators);
    passwordControlConfirm.setValidators(passwordControlConfirmValidators);

    this.form = fb.group({
      username: new FormControl(localStorage.getItem('username'),  Validators.compose([Validators.required, ValidatorUtils.alphaNumeric()])),
      password: passwordControl,
      passwordConfirm: passwordControlConfirm,
      firstName: new FormControl(localStorage.getItem('firstName'),  Validators.compose([Validators.min(3)])),
      lastName: new FormControl(localStorage.getItem('lastName'),  Validators.compose([Validators.min(3)])),
      email: new FormControl(localStorage.getItem('email'),  Validators.compose([Validators.email]))
    });
  }

  ngOnInit()
  {
    console.info('sign up');
  }

  getControlValue(fieldName: string)
  {
    return this.form.get(fieldName).value;
  }

  onSubmit()
  {
    const self = this;
    const subscription = this.authenticateService.register(
      this.getControlValue('username'),
      this.getControlValue('password'),
      this.getControlValue('firstName'),
      this.getControlValue('lastName'),
      this.getControlValue('email')).subscribe({
      error(msg)
      {
        subscription.unsubscribe();
        Swal.fire({text: msg, icon: 'error'});
      },
      complete()
      {
        subscription.unsubscribe();
        self.router.navigate(['']);
      }
    });
  }

}
