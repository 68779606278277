<div>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">News</h4>
    <button type="button" class="close" aria-label="Close" (click)="onClose()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <iframe class="w-100 h-100" [src]="iframeSrc"></iframe>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="onClose()">Close</button>
  </div>
</div>
