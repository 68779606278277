import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Router} from '@angular/router';
import {EarthquakeService} from '../../../services/earthquake/earthquake.service';
import * as _ from 'lodash';
import {EarthquakeHazard} from '../../../services/earthquake/earthquake-hazard';
import {Location} from '../../../services/hazards/abstract-hazard';
import {GeoLocationService} from '../../../services/geo-location.service';
import {GoogleMap} from '@agm/core/services/google-maps-types';
import {AgmMap} from '@agm/core';

@Component({
  selector: 'app-map-content',
  templateUrl: './map-modal-content.component.html',
  styleUrls: ['./map-modal-content.component.scss']
})
export class MapModalContentComponent implements OnInit, AfterViewInit
{
  hazardID: string;
  hazards: EarthquakeHazard[];
  map?: GoogleMap;
  location?: Location;
  myLocationIcon = {url: 'assets/my_location.svg', scaledSize: { width: 50,  height: 50}};
  earthquakeIcon = {url: 'assets/earthquake.gif', scaledSize: { width: 35,  height: 35}};

  @ViewChild('AgmMap') agmMap: AgmMap;

  constructor(private geoLocationService: GeoLocationService, private activeModal: NgbActiveModal,
              private earthquakeService: EarthquakeService, private modalService: NgbModal, private router: Router)
  {}

  ngAfterViewInit()
  {
    if (this.geoLocationService.location)
      this.location = this.geoLocationService.location;

    const self = this;
    this.geoLocationService.subject.subscribe(location  =>
    {
      self.location = location;
    });

    this.agmMap.mapReady.subscribe(map =>
    {
      self.map = map;
      self.fitToBounds();
    });
  }

  ngOnInit()
  {
    const item = _.find(this.earthquakeService.cache.getItems(), {id: this.hazardID});
    if (_.isNil(item))
    {
      return;
    }
    this.hazards = [item];
  }

  fitToBounds()
  {
    const bounds = new window['google'].maps.LatLngBounds();
    bounds.extend(new window['google'].maps.LatLng(this.location.latitude, this.location.longitude));

    this.hazards.forEach(hazard =>
    {
      bounds.extend(new window['google'].maps.LatLng(hazard.location.latitude, hazard.location.longitude));
    });
    this.map.fitBounds(bounds);
  }

  onClose()
  {
    this.activeModal.dismiss();
  }
}
