import {Injectable, Output} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Application, Customer, Preferences, Role, User, UserID} from '../network/typescript-angular-client-generated';
import {Observable, Subject} from 'rxjs';
import {Profile} from './profile';
import {Location} from './hazards/abstract-hazard';
import {UserServiceEx} from '../network/user-service-ex';
import {environment} from '../../environments/environment';
import * as _ from 'lodash';

enum ApiSection
{
  admin = '/eq-admin-api/api/v1',
  user = '/eq-external-api/api/v1'
}

export enum AuthenticationEvent
{
  none = 1,
  didLogin,
  didLogout
}

@Injectable({providedIn: 'root'})
export class UserService
{
  static systemUser = 'SystemUser';
  static systemPassword = 'Q!w2E#r4';

  private subject: Subject<AuthenticationEvent> = new Subject<AuthenticationEvent>();
  @Output() changes: Observable<AuthenticationEvent>;

  private profile?: Profile;

  authenticated(): boolean
  {
    return this.profile.getToken() != null;
  }

  getProfile(): Profile
  {
    return this.profile;
  }

  constructor(private http: HttpClient, private userService: UserServiceEx)
  {
    this.profile = Profile.load();
    console.info('UserService constructor: ' + JSON.stringify(this.profile));
    this.changes = this.subject.asObservable();
  }

  setup(): Observable<void>
  {
    console.info('UserService setup');
    return Observable.create( (observer) =>
    {
      observer.next();
    });

  }

  refreshToken(): Observable<string>
  {
    console.info('UserService refreshToken');
    return Observable.create((observer) =>
    {
      this.login(this.profile.getUsername(), this.profile.getPassword(), this.profile.isDefault()).subscribe(() =>
      {
          observer.next(this.profile.getToken());
      }, err =>
      {
        observer.error(err);
      });
    });
  }

  login(username: string, password: string, isDefault: boolean): Observable<void>
  {
    console.info(JSON.stringify(environment));
    this.userService.setBasePath(environment.apiUrl + ApiSection.user);
    this.userService.defaultHeaders = new HttpHeaders();
    this.userService.configuration.username = username;
    this.userService.configuration.password = password;

    return Observable.create( (observer) => {

      this.userService.authenticate(Application._1, Customer._2).subscribe(
        value =>
        {
          this.profile.setID(value.id);
          console.info('LOGIN: OK');
          this.profile.setDefault(isDefault);
          this.profile.setToken(value.token);
          this.profile.setUsername(username);
          this.profile.setPassword(password);
          console.info(JSON.stringify(this.profile));
          observer.next();
        },
        error =>
        {
          observer.error(error.error.error_description);
        },
        () =>
        {
          this.userService.configuration.username = null;
          this.userService.configuration.password = null;
          observer.complete();
          this.subject.next(AuthenticationEvent.didLogin);
        }
      );
    });
  }

  logout()
  {
    this.profile.reset();
    this.userService.defaultHeaders = new HttpHeaders();
    this.subject.next(AuthenticationEvent.didLogout);
  }

  private getSystemToken(): Observable<string>
  {
    this.userService.setBasePath(environment.apiUrl + ApiSection.user);
    this.userService.defaultHeaders = new HttpHeaders();
    this.userService.configuration.username = UserService.systemUser;
    this.userService.configuration.password = UserService.systemPassword;
    const self = this;

    return new Observable((observer) =>
    {
      this.userService.authenticate(Application._1, Customer._1).subscribe(
        value => { observer.next(value.token); },
        error => { observer.error(error.error.error_description); },
        () =>
        {
          this.userService.configuration.username = null;
          this.userService.configuration.password = null;
          observer.complete();
        }
      );
    });
  }

  fetchProfile(): Observable<User>
  {
    this.userService.setBasePath(environment.apiUrl + ApiSection.admin);
    const self = this;

    return new Observable((observer) => {

      this.userService.getUser(Application._1, this.profile.getID(), Customer._2).subscribe(profile =>
        {
          self.profile.setUsername(profile.name);

          if (profile.first_name)
          {
            self.profile.setFirstName(profile.first_name);
          }
          if (profile.last_name)
          {
            self.profile.setLastName(profile.last_name);
          }
          if (profile.email)
          {
            self.profile.setEmail(profile.email);
          }
          if (profile.eq_preferences)
          {
            if (profile.eq_preferences.radius)
            {
              self.profile.getEarthquakeSettings().setRadius(profile.eq_preferences.radius);
            }
            if (profile.eq_preferences.magnitude)
            {
              self.profile.getEarthquakeSettings().setMaxMagnitude(profile.eq_preferences.magnitude);
            }
          }
          observer.next(profile);
          observer.complete();
        }, error => { observer.error(error.error.error_description); }
      );
    });
  }

  private createUser(username: string, password: string, firstName?: string, lastName?: string, email?: string): Observable<UserID>
  {
    return new Observable((observer) =>
    {
      const self = this;

      self.userService.setBasePath(environment.apiUrl + ApiSection.admin);

      const user: User =
      {
        associate_with: Customer._2,
        name: username,
        password: btoa(password),
        first_name: firstName ? firstName : undefined,
        last_name: lastName ? lastName : undefined,
        email: email ? email : undefined,
        roles: [Role.Regular],
        eq_preferences: undefined,
        credentials: undefined
      };

      self.userService.createUser(Application._1, user, Customer._1).subscribe(value =>
        {
          observer.next(value);
        },
        error => { observer.error(error.error.error_description); },
        () => { observer.complete(); });
    });
  }

  register(username: string, password: string, firstName?: string, lastName?: string, email?: string): Observable<void>
  {
    return new Observable((observer) =>
    {
      const self = this;
      this.getSystemToken().subscribe(systemToken =>
        {
          self.userService.defaultHeaders = new HttpHeaders().set('Authorization', 'Bearer ' + systemToken);
        }, error => { observer.error(error); },
        () =>
        {
          this.createUser(username, password, firstName, lastName, email).subscribe(userID =>
            {
              self.profile.setDefault(false);
              self.profile.setID(userID.id);
              self.profile.setUsername(username);
              self.profile.setPassword(password);

              if (firstName)
              {
                self.profile.setFirstName(firstName);
              }
              if (lastName)
              {
                self.profile.setLastName(lastName);
              }
              if (email)
              {
                self.profile.setEmail(email);
              }

              observer.next();

            }, error => { observer.error(error); },
            () =>
            {
              this.login(self.profile.getUsername(), self.profile.getPassword(), false).subscribe(
                () => {},
                error => { observer.error(error); },
                () =>
                {
                  observer.complete();
                });
            });
        });
    });
  }

  update(username?: string, password?: string, firstName?: string, lastName?: string, email?: string,
         earthquakeMaxMagnitude?: number, earthquakeRadius?: number): Observable<void>
  {
    this.userService.setBasePath(environment.apiUrl + ApiSection.admin);

    return new Observable((observer) =>
    {
      const self = this;
      let preferences: Preferences | undefined;

      if (earthquakeMaxMagnitude || earthquakeRadius)
      {
        preferences =
        {
          magnitude: earthquakeMaxMagnitude ? earthquakeMaxMagnitude : this.profile.getEarthquakeSettings().getMaxMagnitude(),
          radius: earthquakeRadius ? earthquakeRadius : this.profile.getEarthquakeSettings().getRadius()
        };
      }

      const user: User =
      {
        associate_with: Customer._2,
        name: username ? username : undefined,
        password: password ? password : undefined,
        first_name: firstName ? firstName : undefined,
        last_name: lastName ? lastName : undefined,
        email: email ? email : undefined,
        eq_preferences: preferences,
        credentials: undefined,
        roles: undefined
      };

      this.userService.updateUser(Application._1, this.profile.getID(), user, Customer._2).subscribe(
        value =>
        {
          if (username)
          {
            self.profile.setUsername(username);
          }
          if (password)
          {
            self.profile.setPassword(password);
          }
          if (firstName)
          {
            self.profile.setFirstName(firstName);
          }
          if (lastName)
          {
            self.profile.setLastName(lastName);
          }
          if (email)
          {
            self.profile.setEmail(email);
          }
          if (earthquakeMaxMagnitude || earthquakeRadius)
          {
            if (earthquakeMaxMagnitude)
            {
              self.profile.getEarthquakeSettings().setMaxMagnitude(earthquakeMaxMagnitude);
            }
            if (earthquakeRadius)
            {
              self.profile.getEarthquakeSettings().setRadius(earthquakeRadius);
            }
          }

          console.info();
        },
        error =>
        {
          observer.error(error.error.error_description);
        },
        () =>
        {
          observer.complete();
          self.subject.next(AuthenticationEvent.didLogin);
        }
      );
    });
  }

  updateLocation(location: Location)
  {
    console.info('UserService updateLocation: ' + JSON.stringify(this.profile));
    this.userService.setBasePath(environment.apiUrl + ApiSection.admin);
    this.userService.updateLocation(Application._1, this.profile.getID(),
      {latitude: location.latitude, longitude: location.longitude},
      Customer._2).subscribe(next =>
    {
      console.info('location updated');
    }, error =>
    {
      console.error(JSON.stringify(error));
    });
  }
}
