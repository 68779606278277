import * as _ from 'lodash';

export enum EarthquakeSourceType
{
  us = 'us',
  eu = 'eu'
}

export class EarthquakeSource
{
  static sources: EarthquakeSource[] =
  [
    new EarthquakeSource(EarthquakeSourceType.us),
    new EarthquakeSource(EarthquakeSourceType.eu),
  ];
  private source: EarthquakeSourceType;

  constructor(type: EarthquakeSourceType)
  {
    this.source = type;
  }

  static load(): EarthquakeSource
  {
    let source = EarthquakeSourceType[localStorage.getItem('earthquake.source')];
    if (_.isNil(source))
    {
      source = EarthquakeSourceType.us;
    }
    const result = new EarthquakeSource(source);
    result.save();
    return result;
  }

  save()
  {
    localStorage.setItem('earthquake.source', this.source);
  }

  getType(): EarthquakeSourceType
  {
    return this.source;
  }

  getTitle(): string
  {
    switch (this.source)
    {
      case EarthquakeSourceType.us:
        return 'United States';
      case EarthquakeSourceType.eu:
        return 'European';
    }
  }

  reset()
  {
    this.source = EarthquakeSourceType.us;
    localStorage.removeItem('earthquake.source');
  }
}

export class EarthquakeSettings
{
  private source: EarthquakeSource;
  private maxMagnitude?: number;
  private radius?: number;

  static load(): EarthquakeSettings
  {
    const settings = new EarthquakeSettings();

    const maxMagnitudeString = localStorage.getItem('earthquake.maxMagnitude');
    settings.maxMagnitude = _.isNil(maxMagnitudeString) ? null : _.toNumber(maxMagnitudeString);

    const radiusString = localStorage.getItem('earthquake.radius');
    settings.radius = _.isNil(radiusString) ? null : _.toNumber(radiusString);
    settings.source = EarthquakeSource.load();
    return settings;
  }

  reset()
  {
    this.source.reset();
    this.maxMagnitude = null;
    this.radius = null;
    localStorage.removeItem('earthquake.maxMagnitude');
    localStorage.removeItem('earthquake.radius');
  }

  getMaxMagnitude(): number | null
  {
    return this.maxMagnitude;
  }

  getRadius(): number | null
  {
    return this.radius;
  }

  getSource(): EarthquakeSource
  {
    return this.source;
  }

  getSourceOptions(): EarthquakeSource[]
  {
    return EarthquakeSource.sources;
  }

  setMaxMagnitude(magnitude: number)
  {
    this.maxMagnitude = magnitude;
    localStorage.setItem('earthquake.maxMagnitude', magnitude.toString());
  }

  setRadius(radius: number)
  {
    this.radius = radius;
    localStorage.setItem('earthquake.radius', radius.toString());
  }

  setSource(value: EarthquakeSource)
  {
    this.source = value;
    this.source.save();
  }
}

export class Profile
{
  static defaultUser = 'yury31';
  static defaultPassword = 'testdrive';
  static defaultFirstName = 'anonymous';
  static defaultLastName = 'anonymous';
  static defaultEmail = 'fake@example.com';

  private default: boolean;
  private id: number;

  private token: string;
  private username: string;
  private password: string;

  private firstName: string;
  private lastName: string;
  private email: string;

  private earthquakeSettings: EarthquakeSettings;

  private constructor() {}

  static load(): Profile
  {
    const profile = new Profile();

    const id  = localStorage.getItem('id');
    const isDefault = localStorage.getItem('default');

    if (_.isNil(id) || _.isNil(isDefault))
    {
      profile.setDefault(true);
      profile.setID(46);
      profile.setUsername(this.defaultUser);
      profile.setPassword(this.defaultPassword);
      profile.setFirstName(this.defaultFirstName);
      profile.setLastName(this.defaultLastName);
      profile.setEmail(this.defaultEmail);
    }
    else
    {

      profile.id = parseInt(id, 10);
      profile.token = localStorage.getItem('token');
      profile.default = _.isEqual(isDefault, 'true');
      profile.username = localStorage.getItem('username');
      profile.password = localStorage.getItem('password');
      profile.firstName = localStorage.getItem('firstName');
      profile.lastName = localStorage.getItem('lastName');
      profile.email = localStorage.getItem('email');
    }
    profile.earthquakeSettings = EarthquakeSettings.load();
    return profile;
  }

  reset()
  {
    this.id = null;
    this.token = null;
    localStorage.removeItem('id');
    localStorage.removeItem('token');
    this.earthquakeSettings.reset();
  }

  isDefault(): boolean
  {
    return this.default;
  }

  getID(): number
  {
    return this.id;
  }

  getToken(): string
  {
    return this.token;
  }

  getUsername(): string
  {
    return this.username;
  }

  getPassword(): string
  {
    return this.password;
  }

  getEmail(): string
  {
    return this.email;
  }

  getFirstName(): string
  {
    return this.firstName;
  }

  getLastName(): string
  {
    return this.lastName;
  }

  getEarthquakeSettings(): EarthquakeSettings
  {
    return this.earthquakeSettings;
  }

  setDefault(value: boolean)
  {
    this.default = value;
    localStorage.setItem('default', value.toString());
  }

  setID(id: number)
  {
    this.id = id;
    localStorage.setItem('id', id.toString());
  }

  setToken(token: string)
  {
    this.token = token;
    localStorage.setItem('token', token);
  }

  setUsername(username: string)
  {
    this.username = username;
    localStorage.setItem('username', username);
  }

  setPassword(password: string)
  {
    this.password = password;
    localStorage.setItem('password', password);
  }

  setFirstName(firstName: string)
  {
    this.firstName = firstName;
    localStorage.setItem('firstName', firstName);
  }

  setLastName(lastName: string)
  {
    this.lastName = lastName;
    localStorage.setItem('lastName', lastName);
  }

  setEmail(email: string)
  {
    this.email = email;
    localStorage.setItem('email', email);
  }

}
