/**
 * Alert System
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.2
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { Application } from '../model/application';
import { Customer } from '../model/customer';
import { Location } from '../model/location';
import { LoginInfo } from '../model/loginInfo';
import { User } from '../model/user';
import { UserID } from '../model/userID';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class UserService {

    protected basePath = 'http://195.34.120.2:{port}/{basePath}';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * authenicate
     * authenicate
     * @param app_id 
     * @param cust_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public authenticate(app_id: Application, cust_id?: Customer, observe?: 'body', reportProgress?: boolean): Observable<LoginInfo>;
    public authenticate(app_id: Application, cust_id?: Customer, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LoginInfo>>;
    public authenticate(app_id: Application, cust_id?: Customer, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LoginInfo>>;
    public authenticate(app_id: Application, cust_id?: Customer, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (app_id === null || app_id === undefined) {
            throw new Error('Required parameter app_id was null or undefined when calling authenticate.');
        }


        let headers = this.defaultHeaders;
        if (app_id !== undefined && app_id !== null) {
            headers = headers.set('app-id', String(app_id));
        }
        if (cust_id !== undefined && cust_id !== null) {
            headers = headers.set('cust-id', String(cust_id));
        }

        // authentication (BasicAuth) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<LoginInfo>('get',`${this.basePath}/tokens`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * create user
     * create user
     * @param app_id 
     * @param body 
     * @param cust_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createUser(app_id: Application, body?: User, cust_id?: Customer, observe?: 'body', reportProgress?: boolean): Observable<UserID>;
    public createUser(app_id: Application, body?: User, cust_id?: Customer, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserID>>;
    public createUser(app_id: Application, body?: User, cust_id?: Customer, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserID>>;
    public createUser(app_id: Application, body?: User, cust_id?: Customer, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (app_id === null || app_id === undefined) {
            throw new Error('Required parameter app_id was null or undefined when calling createUser.');
        }



        let headers = this.defaultHeaders;
        if (app_id !== undefined && app_id !== null) {
            headers = headers.set('app-id', String(app_id));
        }
        if (cust_id !== undefined && cust_id !== null) {
            headers = headers.set('cust-id', String(cust_id));
        }

        // authentication (BearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<UserID>('post',`${this.basePath}/users`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * delete user
     * delete user
     * @param app_id 
     * @param userID 
     * @param cust_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteUser(app_id: Application, userID: number, cust_id?: Customer, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteUser(app_id: Application, userID: number, cust_id?: Customer, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteUser(app_id: Application, userID: number, cust_id?: Customer, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteUser(app_id: Application, userID: number, cust_id?: Customer, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (app_id === null || app_id === undefined) {
            throw new Error('Required parameter app_id was null or undefined when calling deleteUser.');
        }

        if (userID === null || userID === undefined) {
            throw new Error('Required parameter userID was null or undefined when calling deleteUser.');
        }


        let headers = this.defaultHeaders;
        if (app_id !== undefined && app_id !== null) {
            headers = headers.set('app-id', String(app_id));
        }
        if (cust_id !== undefined && cust_id !== null) {
            headers = headers.set('cust-id', String(cust_id));
        }

        // authentication (BearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('delete',`${this.basePath}/users/${encodeURIComponent(String(userID))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * get user
     * get user
     * @param app_id 
     * @param userID 
     * @param cust_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUser(app_id: Application, userID: number, cust_id?: Customer, observe?: 'body', reportProgress?: boolean): Observable<User>;
    public getUser(app_id: Application, userID: number, cust_id?: Customer, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<User>>;
    public getUser(app_id: Application, userID: number, cust_id?: Customer, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<User>>;
    public getUser(app_id: Application, userID: number, cust_id?: Customer, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (app_id === null || app_id === undefined) {
            throw new Error('Required parameter app_id was null or undefined when calling getUser.');
        }

        if (userID === null || userID === undefined) {
            throw new Error('Required parameter userID was null or undefined when calling getUser.');
        }


        let headers = this.defaultHeaders;
        if (app_id !== undefined && app_id !== null) {
            headers = headers.set('app-id', String(app_id));
        }
        if (cust_id !== undefined && cust_id !== null) {
            headers = headers.set('cust-id', String(cust_id));
        }

        // authentication (BearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<User>('get',`${this.basePath}/users/${encodeURIComponent(String(userID))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * update location
     * update location
     * @param app_id 
     * @param userID 
     * @param body 
     * @param cust_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateLocation(app_id: Application, userID: number, body?: Location, cust_id?: Customer, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public updateLocation(app_id: Application, userID: number, body?: Location, cust_id?: Customer, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public updateLocation(app_id: Application, userID: number, body?: Location, cust_id?: Customer, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public updateLocation(app_id: Application, userID: number, body?: Location, cust_id?: Customer, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (app_id === null || app_id === undefined) {
            throw new Error('Required parameter app_id was null or undefined when calling updateLocation.');
        }

        if (userID === null || userID === undefined) {
            throw new Error('Required parameter userID was null or undefined when calling updateLocation.');
        }



        let headers = this.defaultHeaders;
        if (app_id !== undefined && app_id !== null) {
            headers = headers.set('app-id', String(app_id));
        }
        if (cust_id !== undefined && cust_id !== null) {
            headers = headers.set('cust-id', String(cust_id));
        }

        // authentication (BearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/users/${encodeURIComponent(String(userID))}/location`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * update user
     * update user
     * @param app_id 
     * @param userID 
     * @param body 
     * @param cust_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateUser(app_id: Application, userID: number, body?: User, cust_id?: Customer, observe?: 'body', reportProgress?: boolean): Observable<UserID>;
    public updateUser(app_id: Application, userID: number, body?: User, cust_id?: Customer, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserID>>;
    public updateUser(app_id: Application, userID: number, body?: User, cust_id?: Customer, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserID>>;
    public updateUser(app_id: Application, userID: number, body?: User, cust_id?: Customer, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (app_id === null || app_id === undefined) {
            throw new Error('Required parameter app_id was null or undefined when calling updateUser.');
        }

        if (userID === null || userID === undefined) {
            throw new Error('Required parameter userID was null or undefined when calling updateUser.');
        }



        let headers = this.defaultHeaders;
        if (app_id !== undefined && app_id !== null) {
            headers = headers.set('app-id', String(app_id));
        }
        if (cust_id !== undefined && cust_id !== null) {
            headers = headers.set('cust-id', String(cust_id));
        }

        // authentication (BearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<UserID>('put',`${this.basePath}/users/${encodeURIComponent(String(userID))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
