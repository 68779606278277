import {AbstractHazard, HazardSeverity, Location, HazardType} from '../hazards/abstract-hazard';
import * as _ from 'lodash';

export enum Source
{
  us = 'us',
  eu = 'eu'
}

export class EarthquakeHazard implements AbstractHazard
{
  id: string;
  source: Source;
  datetime: Date;
  location: Location;
  place: string;
  depth: number;
  magnitude: number;
  severity: HazardSeverity;
  severityString: string;
  title: string;
  tsunami: boolean;
  type: HazardType;

  private static detectSeverity(magnitude: number): HazardSeverity
  {
    if (magnitude < 3)
    {
      return HazardSeverity.low;
    }
    else if (magnitude < 6)
    {
      return HazardSeverity.medium;
    }
    else
    {
      return HazardSeverity.high;
    }
  }

  static fromObject(object: {[key: string]: any}): EarthquakeHazard|null
  {
    const id = object['id'];
    const source = object['source'] as Source;
    const timestamp = object['timestamp'];
    const location = Location.fromObject(object['coordinates']);
    const depth = object['coordinates']['depth'];
    const title = _.isNil(object['title']) ? 'Unknown' : object['title'];
    const magnitude = object['magnitude'];
    const tsunami = _.isNil(object['tsunami']) ? false : object['tsunami'];
    if (_.isNil(id) || _.isNil(source) || _.isNil(timestamp) || _.isNil(location) || _.isNil(depth) || _.isNil(title) || _.isNil(magnitude) || _.isNil(tsunami))
    { return null; }

    return new EarthquakeHazard(id, source, new Date(timestamp), location, title, depth, magnitude, magnitude, tsunami);
  }

  constructor(id: string, source: Source, datetime: Date, location: Location, place: string, depth: number, title: string, magnitude: number, tsunami: boolean)
  {
    this.id = id;
    this.source = source;
    this.datetime = datetime;
    this.location = location;
    this.place = place;
    this.depth = depth;
    this.magnitude = magnitude;
    this.severity = EarthquakeHazard.detectSeverity(magnitude);
    this.severityString = ['None', 'Low', 'Medium', 'High'][this.severity - 1];
    this.title = title;
    this.tsunami = tsunami;
    this.type = HazardType.earthquake;
  }
}
