import {AfterViewChecked, AfterViewInit, Component, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {ActivatedRoute} from '@angular/router';
import {EarthquakeService} from '../../../services/earthquake/earthquake.service';
import {AbstractHazard} from '../../../services/hazards/abstract-hazard';
import * as _ from 'lodash';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-news-modal-content',
  templateUrl: './news-modal-content.component.html'
})
export class NewsModalContentComponent implements OnInit
{
  hazardID?: string;
  iframeSrc?: SafeResourceUrl;

  constructor(private route: ActivatedRoute, private earthquakeService: EarthquakeService, private  domSanitizer: DomSanitizer, private activeModal: NgbActiveModal) {}

  private makeURL(item: AbstractHazard)
  {
    const baseURL = 'https://www.google.com/search?q=';
    const date = new Date(item.datetime);
    const month = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December' ][date.getMonth()];
    const dateString = _.join([date.getFullYear(), date.getMonth(), date.getDay()], '/');
    const terms = [dateString, item.location, item['magnitude'], 'earthquake', 'hazard'];
    const searchTerm = _.join(terms, '+');
    const searchTermEscaped = encodeURI(baseURL + searchTerm);
    return this.domSanitizer.bypassSecurityTrustResourceUrl(searchTermEscaped);
  }

  ngOnInit()
  {
    const item = _.find(this.earthquakeService.cache.getItems(), {id: this.hazardID});
    if (_.isNil(item))
    {
      return;
    }

    this.iframeSrc = this.makeURL(item);
    console.info('src: ' + this.iframeSrc);
  }

  onClose()
  {
    this.activeModal.dismiss();
  }
}
