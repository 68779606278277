import * as _ from 'lodash';
import {DataCacheItem} from '../data-cache';

export class ChatMessage implements DataCacheItem
{
  readonly id: string;
  private senderID: number;
  readonly senderName: string;
  readonly content: string;

  static fromObject(object: {[key: string]: any}): ChatMessage|null
  {
    const senderId = object.senderId as number;
    const sender = object.sender as string;
    const content = object.content as string;

    if (_.isNil(senderId) || _.isNil(sender) || _.isNil(content))
    { return null; }

    return new ChatMessage(senderId, sender, content);
  }

  constructor(senderID: number, senderName: string, content: string)
  {
    this.senderID = senderID;
    this.senderName = senderName;
    this.content = content;
  }

  asString(): string
  {
    const payload = {
      senderId: this.senderID,
      sender: this.senderName,
      content: this.content
    };
    return JSON.stringify(payload);
  }
}
