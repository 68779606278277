<br>
<br>
<br>
<br>
<div class="container">

  <div class="row justify-content-center">
    <h1 class="col-xs-12">Authorization</h1>
  </div>

  <div class="row justify-content-center">
    <br/>
    <div class="col-lg-4 col-lg-offset-4 col-sm-6 col-sm-offset-3 col-xs-10 col-xs-offset-1">

      <form [formGroup] = "form">
        <input type="text" placeholder="Username..." formControlName="username" class="form-control"/>
        <br/>
        <input type="password" placeholder="Password..." formControlName="password" class="form-control"/>
        <br/>
      </form>

      <div class="row no-gutters">
        <a routerLink="../" href="#" class="btn btn-primary button-left col">Cancel</a>
        <button type="button" class="btn btn-primary button-right col" (click)="onLogin()" [disabled]="!form.valid">Login</button>
      </div>

      <br/>
      <br/>
      <div class="row">
        <a class="col-6" routerLink="/reset-password">Forgot Password</a>
      </div>
    </div>
  </div>

</div>
