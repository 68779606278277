import {DataCacheItem} from '../data-cache';
import * as _ from 'lodash';

export enum HazardType
{
  earthquake = 1,
  weather = 2,
  pollution = 3
}

export enum HazardSeverity
{
  none = 1,
  low,
  medium,
  high
}

export class Location
{
  readonly latitude: number;
  readonly longitude: number;

  static fromObject(payload: {[key: string]: any}): Location|null
  {
    if (_.isNil(payload))
    { return null; }

    const latitude = payload['latitute'];
    const longitude = payload['longitude'];
    if (_.isNil(latitude) || _.isNil(longitude))
    { return null; }

    return new Location(latitude, longitude);
  }

  constructor(latitude: number, longitude: number)
  {
    this.latitude = latitude;
    this.longitude = longitude;
  }
}

export interface AbstractHazard extends DataCacheItem
{
  id: string;
  type: HazardType;
  title: string;
  severity: HazardSeverity;
  location?: Location;
  datetime: Date;
}
