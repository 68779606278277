import * as _ from 'lodash';

export interface DataCacheItem
{
  id: string;
}

export class DataCache<T extends DataCacheItem>
{
  private items: {[key: string]: T};
  private page: number;
  private end: boolean;

  constructor()
  {
    this.items = {};
    this.page = 0;
    this.end = false;
  }

  add(items: T[])
  {
    if (!items.length)
    {
      this.end = true;
    }
    else
    {
      console.info('before: ' + Object.entries(this.items).length + ' wanna add: ' + items.length);
      items.forEach(item => { this.items[item.id] = item; });
      console.info('after: ' + Object.entries(this.items).length);
      this.page += 1;
    }
  }

  getItems(): Array<T>
  {
    const result = Object.values(this.items);
    return result;
  }

  getPage(): number | null
  {
    return this.page;
  }

  reset()
  {
    const self = this;
    Object.keys(this.items).forEach(key => { delete self.items[key]; });
    this.page = 0;
    this.end = false;
  }
}
